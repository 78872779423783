import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {

        /* NavBar */
        isNavBarVisible: true,

        /* FooterBar */
        isFooterBarVisible: true,

        /* Aside */
        isAsideVisible: true,
        isAsideMobileExpanded: false,

        /* DATA APP */
        dataApp: axios.create({
            baseURL: '/openapi/',
            timeout: 30000,
            headers: {
                'Content-Type': 'application/json',
                "Accept": "application/json"
            }
        }),

        /* API */
         api: axios.create({
            baseURL: '/api/',
            timeout: 30000,
            headers: {
               'Content-Type': 'application/json',
               "Accept": "application/json"
            }
         }),

        /* Influx API */
         bms: axios.create({
            baseURL: 'https://influxapi.digital-passport.org/bms',
            timeout: 30000,
            headers: {
               'Content-Type': 'application/json',
               "Accept": "application/json"
            }
        })
    },
    mutations: {
        /* A fit-them-all commit */
        basic(state, payload) {
            state[payload.key] = payload.value
        },

        /* Aside Mobile */
        asideMobileStateToggle(state, payload = null) {
            const htmlClassName = 'has-aside-mobile-expanded'

            let isShow

            if (payload !== null) {
                isShow = payload
            } else {
                isShow = !state.isAsideMobileExpanded
            }

            if (isShow) {
                document.documentElement.classList.add(htmlClassName)
            } else {
                document.documentElement.classList.remove(htmlClassName)
            }

            state.isAsideMobileExpanded = isShow
        }
    }
})